import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { StatusIconComponent } from "../../../../components";
import { colors } from "../../../../styles/colors";
import {
  updateOfferExpected,
} from "../../services/servicesPricing";

const ExpectedOffer = ({
  user,
  deal_id,
  expectedOffer,
  setActiveModalPricing,
}) => {
  const [showModal, setShowModal] = useState(false);

  // Estado que guarda lo que se muestra en el input (ej.: "$7.000.000" o "7.000 USD")
  const [expectedOfferValue, setExpectedOfferValue] = useState("");

  // Estado para la moneda seleccionada ("ARS" o "USD").
  const [expectedOfferCurrency, setExpectedOfferCurrency] = useState(
    expectedOffer?.currency === "USD" ? "USD" : "ARS"
  );

  const { register, handleSubmit } = useForm();

  const closeModal = () => {
    setShowModal(false);
    setActiveModalPricing(false);
  };


  const handleInputChange = (event) => {
    const inputValue = event.target.value.replace(/\s/g, "");

    // Extraemos solo dígitos
    const numericOnly = inputValue.replace(/\D/g, "");

    // Si no quedan dígitos, limpiamos el input
    if (!numericOnly) {
      setExpectedOfferValue("");
      return;
    }

    // Insertamos '.' cada 3 dígitos de derecha a izquierda
    const reversedDigits = numericOnly.split("").reverse();
    const withDots = [];
    for (let i = 0; i < reversedDigits.length; i++) {
      if (i > 0 && i % 3 === 0) {
        withDots.push(".");
      }
      withDots.push(reversedDigits[i]);
    }
    const formattedNumber = withDots.reverse().join("");


    if (expectedOfferCurrency === "ARS") {

      setExpectedOfferValue(`$${formattedNumber}`);
    } else {

      setExpectedOfferValue(`USD ${formattedNumber}`);
    }
  };

  const sendOfferExpected = () => {
    let valueToSend = expectedOfferValue;

    if (expectedOfferCurrency === "ARS") {

      valueToSend = valueToSend.replace("$", "");
    } else {

      valueToSend = valueToSend.replace("USD ", "");
    }

    const data = {
      "expected_offer.value": valueToSend,
      "expected_offer.currency": expectedOfferCurrency,
      "expected_offer.user": user,
    };

    updateOfferExpected(user?.company, deal_id, data);

    toast.success("Oferta esperada enviada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setShowModal(false);
    setActiveModalPricing(false);
  };


  const getDisplayValue = () => {
    if (!expectedOffer?.value) {
      return "$00.000.000";
    }
    if (expectedOffer?.currency === "ARS") {
      return `$${expectedOffer.value}`;
    }
    // Caso USD
    return `USD ${expectedOffer.value}`;
  };

  return (
    <>
      <div
        className="containerPricing"
        onClick={() => {
          setShowModal(true);
          setActiveModalPricing(true);
        }}
      >
        <p className="textTitle">Oferta esperada</p>
        <div className="divider-pricing" />
        <div className="text-and-icon">
          <p
            className="textPricing"
            style={{
              color:
                expectedOffer?.value !== ""
                  ? colors.primary.primary
                  : colors.primary.secondaryLight,
            }}
          >
            {getDisplayValue()}
          </p>

          {expectedOffer?.status === "" ? (
            <span className="status-icon"></span>
          ) : (
            <StatusIconComponent status={expectedOffer?.status} />
          )}
        </div>
      </div>

      {/* Modal para capturar la nueva oferta esperada */}
      <Modal show={showModal} onHide={closeModal} centered dialogClassName="myModal">
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="titleModal">Oferta esperada</p>

            {/* Form principal */}
            <form style={{ width: "100%" }} onSubmit={handleSubmit(sendOfferExpected)}>
              {/* Radio buttons para elegir la moneda */}
              <Form.Group>
                <Form.Label className="mb-22 textModalConsignment">
                  ¿En qué moneda deseas ingresar la oferta?
                </Form.Label>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check
                    type="radio"
                    label="Pesos (ARS)"
                    value="ARS"
                    name="expectedOfferCurrency"
                    inline
                    checked={expectedOfferCurrency === "ARS"}
                    onChange={() => {
                      setExpectedOfferCurrency("ARS");
                      // Limpiar el input al cambiar de moneda
                      setExpectedOfferValue("");
                    }}
                  />
                  <Form.Check
                    type="radio"
                    label="Dólares (USD)"
                    value="USD"
                    name="expectedOfferCurrency"
                    inline
                    checked={expectedOfferCurrency === "USD"}
                    onChange={() => {
                      setExpectedOfferCurrency("USD");
                      // Limpiar el input al cambiar de moneda
                      setExpectedOfferValue("");
                    }}
                  />
                </div>
              </Form.Group>

              {/* Input para ingresar el valor */}
              <input
                className="inputModal mt-3"
                type="text"
                placeholder={
                  expectedOfferCurrency === "ARS"
                    ? "$0.000.000"
                    : "USD 0.000"
                }
                style={{ width: "100%" }}
                {...register("value")}
                value={expectedOfferValue}
                onChange={handleInputChange}
              />

              <div
                style={{ width: "100%" }}
                className="d-flex flex-column align-items-center justify-content-between"
              >
                <button
                  variant="primary"
                  className="btnSendPricing mb-2 mt-3"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </form>

            <button
              variant="secondary"
              onClick={closeModal}
              className="btnCancelarModal"
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpectedOffer;
