import React from 'react'

export default function GraphicsBackOfficePage() {
  return (
    <div className="p-3 h-100">
      <iframe
        width="100%"
        height="100%"
        src="https://lookerstudio.google.com/embed/reporting/104ae19a-ba03-4fa3-8868-4666cc92a5c5/page/RYuZE"
        frameborder="0"
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
      </iframe>
    </div>
  )
}
